<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="invoicesFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">INVOICES</h1>
                        <v-spacer />
                        <v-btn
                            v-if="
                                createInvoice &&
                                    user.id == project.projectManager
                            "
                            rounded
                            color="primary"
                            @click="openNewInvoiceRequest"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.number`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="codeToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.region`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="regionToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.status`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="statusToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.createdBy" class="my-0 text-capitalize">
                        {{ item.createdBy.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.concept`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ getConcept(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="item.client" class="my-0">
                        {{ item.client.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
                <div class="d-flex justify-center">
                    <p v-if="project" class="my-0">
                        {{ item.currency }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ numberFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.billed`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ billedFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.credit`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ creditFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ totalFormat(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.region`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="text-capitalize my-0">
                        {{ item.region }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center">
                    <v-chip
                        :color="statusColor[item.status]"
                        class="white--text text-capitalize"
                    >
                        {{ item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openInvoiceProcess(item)">
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`footer`]>
                <v-row
                    no-gutter
                    class="my-0 mx-0"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="6">
                        <div class="d-flex justify-center font-weight-bold">
                            TOTAL PROJECT PRICE = {{ formatCurrency(project) }}
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <div class="d-flex justify-center font-weight-bold">
                            TOTAL INVOICED =
                            {{
                                project.totalInvoiced
                                    ? formatInvoiced(project, invoices)
                                    : ' 0 (0%)'
                            }}
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
        <!--ALERT-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--INVOICE DETAILS-->
        <v-dialog
            fullscreen
            v-model="invoiceProcess"
            :retain-focus="false"
            persistent
        >
            <InvoiceDetails
                v-if="invoiceProcess"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project"
                :setting="invoiceSettings"
                @close="closeInvoiceProcess"
                @replaceInvoice="replaceInvoice"
                @approveInvoice="approve"
            />
        </v-dialog>
        <!--PROFORMA DETAILS-->
        <v-dialog
            fullscreen
            v-model="proformaDetails"
            :retain-focus="false"
            persistent
        >
            <ProformaFeatures
                v-if="proformaDetails"
                :invoice="selectedInvoice"
                :project="selectedInvoice.project || {}"
                @replaceInvoice="replaceInvoice"
                @close="closeInvoiceProcess"
                @approveInvoice="approve"
            />
        </v-dialog>
        <!-- INVOICE REQUEST -->
        <v-dialog
            v-model="invoiceRequest"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <InvoiceRequest
                v-if="invoiceRequest"
                :project="selectedProject"
                :invoice="selectedInvoice"
                :setting="invoiceSettings"
                @close="closeInvoiceRequest"
            />
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'Invoices',
    props: {
        project: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        InvoiceDetails: () =>
            import('@/components/Invoicing/InvoiceDetails.vue'),
        ProformaFeatures: () =>
            import('@/components/Invoicing/ProformaFeatures.vue'),
        InvoiceRequest: () =>
            import('@/components/Invoicing/InvoiceRequest.vue'),
    },
    data: () => ({
        proformaDetails: false,
        invoiceSettings: {},
        invoiceDetails: false,
        selectedInvoice: {},
        invoiceProcess: false,
        codeToFilter: undefined,
        userToFilter: undefined,
        regionToFilter: undefined,
        statusToFilter: undefined,
        loading: false,
        invoices: [],
        activateAlert: false,
        alertMessage: undefined,
        headers: [
            {
                text: 'ID',
                value: 'number',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CONCEPT',
                value: 'concept',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CLIENT',
                value: 'client',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CURRENCY',
                value: 'currency',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE',
                value: 'amount',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AMOUNT',
                value: 'billed',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREDIT',
                value: 'credit',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL',
                value: 'total',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REGION',
                value: 'region',
                align: 'center',
                sortable: false,
            },
            {
                text: 'STATUS',
                value: 'status',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        companyId: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            approved: '#1976d2',
            billed: '#27bc4f',
            generating: '#edc43b',
            international: '#edc43b',
            closed: '#000000',
            rejected: 'red',
            credit: '#eda73b',
        },
        users: [],
        clients: [],
        createInvoice: false,
        createInvoce: false,
        invoiceRequest: false,
        selectedProject: {},
        user: {},
    }),
    provide() {
        return {
            addInvoice: this.addInvoice,
            confirmSend: this.confirmSend,
        }
    },
    computed: {
        invoicesFiltered() {
            let conditions = []
            if (this.codeToFilter) {
                conditions.push(this.filterCode)
            }
            if (this.userToFilter) {
                conditions.push(this.filterUser)
            }
            if (this.regionToFilter) {
                conditions.push(this.filterRegion)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterStatus)
            }
            if (conditions.length > 0) {
                return this.invoices.filter(invoice => {
                    return conditions.every(condition => {
                        return condition(invoice)
                    })
                })
            }
            this.sort()
            return this.invoices
        },
    },
    watch: {},
    async mounted() {
        try {
            this.loading = true
            const {
                data: { settings },
            } = await API.getSettings()
            this.invoiceSettings = settings.find(
                setting => setting.name.toLowerCase() == 'invoicing'
            )
            this.invoices = await API.getInvoices({
                projectId: this.project.id,
            })
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.createInvoice = user.permissions.includes('createInvoice')
            await this.loadData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async loadData() {
            try {
                this.loading = true
                const {
                    data: { users },
                } = await API.getLiteUsers()
                const {
                    data: { clients },
                } = await API.getLiteClients()
                this.clients = clients
                this.users = users
                for (let invoice of this.invoices) {
                    //set client
                    invoice.client = this.clients.find(
                        client => client.id == invoice.clientId
                    )
                    //set createdBy
                    const userIndex = this.users.findIndex(
                        user => user.id == invoice.createdBy
                    )
                    if (userIndex > -1) {
                        invoice.createdBy = this.users[userIndex]
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openInvoiceProcess(item) {
            try {
                this.loading = true
                this.selectedInvoice = _.cloneDeep(item)
                this.selectedInvoice.project = this.project
                if (
                    this.selectedInvoice.type == 'proforma' &&
                    this.selectedInvoice.status != 'credit'
                ) {
                    this.proformaDetails = true
                } else if (
                    this.selectedInvoice.status == 'credit' ||
                    (this.selectedInvoice.type == 'proforma' &&
                        this.selectedInvoice.status == 'credit')
                ) {
                    if (this.selectedInvoice.project) {
                        this.selectedInvoice.project.quotes = await API.getProjectQuotes(
                            this.selectedInvoice.project.id
                        )
                    }
                    this.creditNoteProcess = true
                    this.creditActions = false
                } else {
                    this.invoiceProcess = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeInvoiceProcess() {
            this.selectedInvoice = {}
            this.invoiceProcess = false
            this.proformaDetails = false
        },
        filterCode(invoice) {
            let number = invoice.number
            if (typeof invoice.number == 'number') {
                number = invoice.number.toString()
            }
            return number
                .toLowerCase()
                .includes(this.codeToFilter.toLowerCase())
        },
        filterUser(invoice) {
            return invoice.createdBy.name
                .toLowerCase()
                .includes(this.userToFilter.toLowerCase())
        },
        filterRegion(invoice) {
            return invoice.region
                .toLowerCase()
                .includes(this.regionToFilter.toLowerCase())
        },
        filterStatus(invoice) {
            return invoice.status
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('L LT')
        },
        numberFormat(invoice) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
            }).format(Math.ceil(invoice.amount))
        },
        billedFormat(invoice) {
            let invoiceAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        invoiceAmount += cost.currentInvoice
                    }
                }
            }
            if (isEmpty(invoice.items)) {
                invoiceAmount = invoice.amount
            }
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? invoiceAmount
                    : Math.ceil(invoiceAmount)
            )
        },
        creditFormat(invoice) {
            let creditAmount = 0
            if (invoice.totalCredit) {
                creditAmount = invoice.totalCredit
            } else {
                for (let key in invoice.items) {
                    for (let cost of invoice.items[key]) {
                        if (cost.creditNoteQty) {
                            creditAmount +=
                                cost.creditNoteQty * cost.pricePerUnit
                        }
                    }
                }
            }

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD'
                    ? creditAmount
                    : Math.ceil(creditAmount)
            )
        },
        totalFormat(invoice) {
            let totalAmount = 0
            for (let key in invoice.items) {
                for (let cost of invoice.items[key]) {
                    if (cost.currentInvoice) {
                        totalAmount += cost.currentInvoice
                    }
                    if (cost.creditNoteQty && !invoice.totalCredit) {
                        totalAmount -= cost.creditNoteQty * cost.pricePerUnit
                    }
                }
            }
            if (isEmpty(invoice.items)) {
                totalAmount = invoice.amount
            }
            if (invoice.totalCredit) {
                totalAmount -= invoice.totalCredit
            }

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: invoice.currency,
                maximumFractionDigits: invoice.currency == 'USD' ? 2 : 0,
            }).format(
                invoice.currency == 'USD' ? totalAmount : Math.ceil(totalAmount)
            )
        },
        sort() {
            this.invoices.sort(
                (a, b) => b.createdOn._seconds - a.createdOn._seconds
            )
        },
        formatCurrency(item) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: item.currency,
            }).format(Math.ceil(item.price))
        },
        formatInvoiced(item, invoices) {
            let totalInvoiced = 0
            if (item.totalInvoiced) {
                totalInvoiced = item.totalInvoiced
            } else {
                invoices.forEach(element => {
                    let invoiceAmount = 0
                    if (!element.conceptInvoice) {
                        for (let key in element.items) {
                            for (let cost of element.items[key]) {
                                if (cost.currentInvoice) {
                                    invoiceAmount += cost.currentInvoice
                                }
                                if (cost.creditNoteQty) {
                                    invoiceAmount -=
                                        cost.creditNoteQty * cost.pricePerUnit
                                }
                            }
                        }
                        totalInvoiced += invoiceAmount
                    }
                })
            }
            const percentage = (totalInvoiced / item.price) * 100
            const formattedInvoiced = new Intl.NumberFormat('de-DE', {
                maximumFractionDigits: item.currency == 'USD' ? 2 : 0,
            }).format(totalInvoiced)
            return `${formattedInvoiced} (${percentage.toFixed(0)}%)`
        },
        replaceInvoice(invoice) {
            const index = this.invoices.findIndex(i => i.id == invoice.id)
            if (index >= 0) {
                this.invoices.splice(index, 1, {
                    ...this.invoices[index],
                    ...invoice,
                })
            }
        },
        async approve() {
            try {
                this.loading = true
                const invoice = await API.approvalInvoice({
                    id: this.selectedInvoice.id,
                    projectId:
                        this.selectedInvoice.type == 'proforma'
                            ? this.selectedInvoice.proformaProject
                            : this.selectedInvoice.projectId,
                    notificationId: 'z4yw1pMEbJAt5r0oz4FZ',
                    settingId: this.invoiceSettings.id,
                })
                this.replaceInvoice(invoice)
                this.approveInvoice = false
                this.activateAlert = true
                this.alertMessage = 'Invoice Request was successfully Approved.'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.selectedInvoice = {}
            }
        },
        getConcept(item) {
            let conceptName = ''
            if (item.concept) {
                const concept = this.invoiceSettings.concepts.find(
                    c => c.id == item.concept
                )
                conceptName = concept ? concept.name : ''
            } else if (item.type == 'proforma') {
                conceptName = item.type
            }

            return conceptName
        },
        async openNewInvoiceRequest() {
            try {
                this.loading = true
                await this.formatProject()
                this.invoiceRequest = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        addInvoice(invoice) {
            this.loadInvoice(invoice)
            this.closeInvoiceRequest()
            this.alertMessage = 'Invoice Request was successfully created.'
            this.activateAlert = true
        },
        confirmSend(invoice) {
            this.loadInvoice(invoice)
            this.closeInvoiceRequest()
            this.alertMessage = 'Invoice Request was successfully created.'
            this.activateAlert = true
        },
        closeInvoiceRequest() {
            this.invoiceRequest = false
        },
        loadInvoice(invoice) {
            //set client
            invoice.client = this.clients.find(
                client => client.id == invoice.clientId
            )
            //set createdBy
            const userIndex = this.users.findIndex(
                user => user.id == invoice.createdBy
            )
            if (userIndex > -1) {
                invoice.createdBy = this.users[userIndex]
            }
            //set Project
            invoice.project = this.project
            this.invoices.splice(0, 0, invoice)
        },
        async formatProject() {
            try {
                this.loading = true
                let baseProject = _.cloneDeep(this.project)
                const quotes = await API.getProjectQuotes(baseProject.id)
                const invoicesByProject = await API.getInvoices({
                    projectId: baseProject.id,
                })
                const invoicesHistory = invoicesByProject.filter(
                    invoice => invoice.type != 'proforma'
                )
                const items = {}
                for (const index in quotes) {
                    const costs = await API.getCosts(quotes[index].id)
                    quotes[index].costs = costs.filter(c => c.type == 'cost')
                    items[quotes[index].id] = quotes[index].costs.map(cost => {
                        return {
                            costId: cost.id,
                            name: cost.reference,
                            pricePerUnit: cost.salePrice2 / cost.quantity,
                            qty: cost.quantity,
                            category: cost.category,
                        }
                    })
                }
                baseProject.quotes = quotes
                const invoice = {
                    project: baseProject,
                    projectId: baseProject.id,
                    clientId: baseProject.client,
                    amount: baseProject.price,
                    currency: baseProject.currency,
                    region: 'local',
                    items,
                }
                // set logs
                for (const invoiceHistory of invoicesHistory) {
                    for (const quoteId of Object.keys(invoice.items)) {
                        for (const cost of invoice.items[quoteId]) {
                            const log = invoiceHistory.items[quoteId]?.find(
                                c => c.costId == cost.costId
                            )
                            if (log && (log.billedQty || log.invoiceQuantity)) {
                                if (log.billedQty) {
                                    cost.billedQty = cost.billedQty
                                        ? cost.billedQty + log.billedQty
                                        : log.billedQty
                                }
                                if (log.invoiceQuantity) {
                                    log.invoiceQty = cost.invoiceQuantity
                                        ? cost.invoiceQuantity +
                                          log.invoiceQuantity
                                        : log.invoiceQuantity
                                }

                                log.number = invoiceHistory.number
                                log.notes = invoiceHistory.notes
                                log.status = invoiceHistory.status
                                const billedBy = this.users.find(
                                    user => user.id == invoiceHistory.billedBy
                                )
                                if (billedBy) {
                                    log.billedBy = billedBy.name
                                    log.billedOn = invoiceHistory.billedOn
                                }
                                const createdBy = this.users.find(
                                    user => user.id == invoiceHistory.createdBy
                                )
                                if (createdBy) {
                                    log.createdBy = createdBy.name
                                    log.createdOn = invoiceHistory.createdOn
                                }
                                if (cost.logs) {
                                    cost.logs.push(log)
                                } else {
                                    cost.logs = [log]
                                }
                                cost.logs.sort(
                                    (a, b) =>
                                        (a.createdOn.seconds ||
                                            a.createdOn._seconds) -
                                        (b.createdOn.seconds ||
                                            b.createdOn._seconds)
                                )
                            }
                        }
                    }
                }
                this.selectedProject = _.cloneDeep(baseProject)
                this.selectedInvoice = _.cloneDeep(invoice)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
